
import {
    FilterFormAlarm,
    FormActions,
    ResponseDataStringArray as rdsa
} from "@/interfaces";
import * as types from "@/store/types";
import {
    Component,
    ModelSync,
    Prop,
    PropSync,
    Ref,
    Vue
} from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

@Component({
    components: {
        CalendarRangeInput: () =>
            import("@/components/layout/CalendarRangeInputPre.vue"),
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    },
    methods: {
        ...mapMutations({
            setFilterLoading: types.MUTATE_APP_FILTER_LOADING,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE,
            setEmpresa: types.MUTATE_EMPRESA
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER,
            Nempresa: types.EMPRESA
        })
    }
})
export default class FilterForm extends Vue {
    @ModelSync("filterForm", "change", { type: Object })
    readonly formAlarm!: FilterFormAlarm;

    @Ref("form") readonly formActions!: FormActions;

    @PropSync("filterFormPanelShow", { type: Boolean || null })
    syncFilterFormPanelShow!: null;

    @Prop({ type: Boolean, default: true, required: false })
    readonly displayDates!: boolean;

    setFilterLoading!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    setEmpresa!: (state: number) => void;

    userIdentifier?: number;
    companies: string[] = [];
    boxes: string[] = [];
    clients: string[] = [];
    installations: string[] = [];
    elements: string[] = [];
    selected: string[] = [];
    start1 = "";
    start2 = "";
    // if !comparative -> dates, else start1 and start2
    first = false;
    isClient = true;

    loading = false;
    parcialCliente: any[] = [];
    parcialInstalacion: any[] = [];
    parcialElemento: any[] = [];
    storageData: any | undefined | null = {};
    storageElements: string | undefined | null = "";
    habemusData = false;

    mounted() {
        this.formAlarm.elements = [];
        this.formAlarm.installation = [];
        this.formAlarm.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
                .toISOString()
                .split("T")[0],
            new Date().toISOString().split("T")[0]
        ];
        this.fetchUser();
    }

    /**
     * Methods
     */

    setFilter() {
        this.$emit("on-filter-updated");
    }

    datesUpdates() {
        if (this.start1 && this.start2) {
            this.formAlarm.dates = [this.start1, this.start2];
        }
    }

    async fetchUser() {
        if (Vue.prototype.$user.get().role == "admin") {
            this.isClient = false;
            this.storageElements = localStorage.getItem("storageElements");
            if (
                this.storageElements == null ||
                this.storageElements == undefined ||
                this.storageElements == ""
            ) {
                try {
                    // throw Error;
                    const storageElementsResponse = await this.$api.SQLstorageElements<
                        any
                    >();
                    if (storageElementsResponse.ok) {
                        localStorage.setItem(
                            "storageElements",
                            JSON.stringify(storageElementsResponse.data)
                        );
                        this.storageElements = localStorage.getItem(
                            "storageElements"
                        );
                        this.habemusData = true;
                        if (
                            this.storageElements != "" &&
                            this.storageElements != null &&
                            this.storageElements != undefined
                        ) {
                            this.storageData = JSON.parse(this.storageElements);
                            this.listEmpresa();
                        }
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        this.showError(error.message);
                    }
                }
            } else {
                this.habemusData = true;
                this.storageData = JSON.parse(this.storageElements);
                this.listEmpresa();
            }
        } else {
            console.log("Role undefined");
        }
    }

    listEmpresa() {
        if (this.habemusData) {
            try {
                this.companies = [];
                const aux: string[] = [];
                if (this.storageData != null && this.storageData != undefined) {
                    this.storageData.forEach(element => {
                        if (!aux.includes(element.empresa)) {
                            aux.push(element.empresa);
                        }
                    });
                }
                this.companies = this.sortList(aux);
                this.formAlarm.company = this.companies;
                this.listCliente();
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
        } else {
            this.showError("Los elementos no han podido ser cargados");
        }
    }

    listCliente() {
        if (this.habemusData) {
            try {
                this.clients = [];
                this.parcialCliente = [];
                const aux: string[] = [];
                this.storageData.forEach(element => {
                    if (this.formAlarm.company.indexOf(element.empresa) != -1) {
                        this.parcialCliente.push(element);
                        if (!aux.includes(element.cliente)) {
                            aux.push(element.cliente);
                        }
                    }
                });
                this.clients = this.sortList(aux);
                this.formAlarm.client = this.clients;
                this.listInstalacion();
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
        } else {
            this.showError("Los elementos no han podido ser cargados");
        }
    }

    listInstalacion() {
        if (this.habemusData) {
            try {
                this.installations = [];
                this.parcialInstalacion = [];
                const aux: string[] = [];
                this.parcialCliente.forEach(element => {
                    if (this.formAlarm.client.indexOf(element.cliente) != -1) {
                        this.parcialInstalacion.push(element);
                        if (!aux.includes(element.instalacion)) {
                            aux.push(element.instalacion);
                        }
                    }
                });
                this.installations = this.sortList(aux);
                this.formAlarm.installation = this.installations;
                this.listElementos();
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
        } else {
            this.showError("Los elementos no han podido ser cargados");
        }
    }

    listElementos() {
        if (this.habemusData) {
            try {
                this.elements = [];
                this.parcialElemento = [];
                const aux: string[] = [];
                this.parcialInstalacion.forEach(element => {
                    if (
                        this.formAlarm.installation.indexOf(
                            element.instalacion
                        ) != -1
                    ) {
                        this.parcialElemento.push(element);
                        if (!aux.includes(element.elemento)) {
                            aux.push(element.elemento);
                        }
                    }
                });
                this.elements = this.sortList(aux);
                this.selected = this.elements;
                this.setElements();
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            }
        } else {
            this.showError("Los elementos no han podido ser cargados");
        }
    }

    setElements() {
        this.formAlarm.elements = [];
        this.parcialElemento.forEach(element => {
            if (this.selected.indexOf(element.elemento) != -1) {
                this.formAlarm.elements.push(element);
            }
        });
        this.setFilterLoading(false);
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    sortList(iterable) {
        const aux: string[] = [];
        const sortedlist: string[] = [];
        iterable.forEach(x => {
            aux.push(
                x
                    .split(" - ")
                    .slice(1)
                    .join(" - ")
            );
        });
        aux.sort();
        aux.forEach(x => {
            const a = iterable.filter(
                y =>
                    y
                        .split(" - ")
                        .slice(1)
                        .join(" - ") == x
            );
            if (a.length) {
                sortedlist.push(a[0]);
            }
        });
        return sortedlist;
    }

    deselectAll() {
        this.selected = [];
        this.formAlarm.elements = [];
    }

    resetForm0() {
        this.formAlarm.client = [];
        this.formAlarm.installation = [];
        this.formAlarm.elements = [];
        this.elements = [];
        this.selected = [];
        this.$emit("no-csv");
    }

    resetForm1() {
        this.formAlarm.installation = [];
        this.formAlarm.elements = [];
        this.elements = [];
        this.selected = [];
        this.$emit("no-csv");
    }

    resetForm2() {
        this.formAlarm.elements = [];
        this.elements = [];
        this.selected = [];
        this.$emit("no-csv");
    }

    resetForm3() {
        this.$emit("no-csv");
    }
}
